import React from 'react';
import {useTranslation} from 'react-i18next';
import Seo from '../components/Seo';
import {Link} from 'gatsby';
import Layout from '../components/Layout';
import RevSliderHome from '../components/sliders/RevSliderHome';
import {languageCourses} from '../utils/utils';
import {withLocaleProvider} from '../locale/LocaleProvider';

export const IndexPage = () => {
  const {t, i18n} = useTranslation();

  return (
    <>
      <Seo
        lang={i18n.language}
        title={t('Home:seo:title')}
        description={t('Home:seo:description')}
        url={t('Home:seo:url')}
      />
      <Layout>
        <RevSliderHome />
        <div className="container-fluid content">
          <div className="clearfix margin-bottom-40"></div>

          <div className="container">
            <div
              className="lead text-center"
              dangerouslySetInnerHTML={{
                __html: t('Home:contents:intro-text'),
              }}
            />

            <div className="clearfix margin-bottom-60"></div>
            <div className="headline">
              <h2>{t('Home:contents:our-products:title')}</h2>
            </div>
            <p>{t('Home:contents:our-products:description')}</p>
            <div className="clearfix margin-bottom-20"></div>
            <div className="col-md-6 home-card">
              <div className="service">
                <i className="fa fa-user service-icon"></i>
                <div className="desc">
                  <h4>{t('Home:contents:our-products:card-1:title')}</h4>
                  <p>{t('Home:contents:our-products:card-1:text')}</p>
                </div>
              </div>
            </div>

            <div className="col-md-6 home-card">
              <div className="service">
                <i className="fa fa-tablet service-icon"></i>
                <div className="desc">
                  <h4>{t('Home:contents:our-products:card-2:title')}</h4>
                  <p>{t('Home:contents:our-products:card-2:text')}</p>
                </div>
              </div>
            </div>

            <div className="col-md-6 home-card">
              <div className="service">
                <i className="fa fa-star service-icon"></i>
                <div className="desc">
                  <h4>{t('Home:contents:our-products:card-3:title')}</h4>
                  <p>{t('Home:contents:our-products:card-3:text')}</p>
                </div>
              </div>
            </div>

            <div className="col-md-6 home-card">
              <div className="service">
                <i className="fa fa-cubes service-icon"></i>
                <div className="desc">
                  <h4>{t('Home:contents:our-products:card-4:title')}</h4>
                  <p>{t('Home:contents:our-products:card-4:text')}</p>
                </div>
              </div>
            </div>
            <div className="clearfix margin-bottom-40"></div>
          </div>
          <figure className="full-width margin-bottom-40"></figure>
          <div className="container">
            <div className="row margin-bottom-20">
              <div className="col-md-8 md-margin-bottom-40">
                <div className="headline">
                  <h2>{t('Home:contents:why-us:title')}</h2>
                </div>
                <div className="row">
                  <div className="col-sm-4">
                    <img
                      src="../../images/fceba35_17_1.jpg"
                      alt=""
                      className="img-responsive margin-bottom-20"
                    />
                  </div>
                  <div className="col-sm-8">
                    <p>{t('Home:contents:why-us:text')}</p>
                    <ul className="list-unstyled margin-bottom-20">
                      <li className="flex-link">
                        <i className="fa fa-chevron-circle-right color-green margin-right-5"></i>{' '}
                        {t('Home:contents:why-us:point-1')}
                      </li>
                      <li className="flex-link">
                        <i className="fa fa-chevron-circle-right color-green margin-right-5"></i>{' '}
                        {t('Home:contents:why-us:point-2')}
                      </li>
                      <li className="flex-link">
                        <i className="fa fa-chevron-circle-right color-green margin-right-5"></i>{' '}
                        {t('Home:contents:why-us:point-3')}
                      </li>
                      <li className="flex-link">
                        <i className="fa fa-chevron-circle-right color-green margin-right-5"></i>{' '}
                        {t('Home:contents:why-us:point-4')}
                      </li>
                      <li className="flex-link">
                        <i className="fa fa-chevron-circle-right color-green margin-right-5"></i>{' '}
                        {t('Home:contents:why-us:point-5')}
                      </li>
                    </ul>
                  </div>
                </div>
                {/* <blockquote>
                  <p>
                    <em>"{t('Home:contents:why-us:blockquote:text')}"</em>
                  </p>
                  <small>
                    <em>{t('Home:contents:why-us:blockquote:author')}</em>
                  </small>
                </blockquote> */}
              </div>
              <div className="col-md-4 courses">
                <div className="headline">
                  <h3>{t('Home:contents:learn-with-us:title')}</h3>
                </div>
                <ul className="list-unstyled margin-bottom-20">
                  {languageCourses
                    .map((lng) => ({
                      to: t(`Home:contents:learn-with-us:point-${lng}-url`),
                      label: t(`Home:contents:learn-with-us:point-${lng}-text`),
                    }))
                    .sort((a, b) => a.label.localeCompare(b.label))
                    .map((item) => (
                      <li key={item.to}>
                        <Link to={item.to} className="flex-link">
                          <i className="fa fa-chevron-circle-right color-green margin-right-5"></i>
                          {item.label}
                        </Link>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
            <div className="call-action-v1 call-action-v1-boxed tag-box-v2 margin-bottom-40">
              <div className="call-action-v1-box">
                <div className="call-action-v1-in">
                  <h3
                    className="color-darker"
                    dangerouslySetInnerHTML={{
                      __html: t('Home:contents:call-to-action:title'),
                    }}
                  />
                  <p>{t('Home:contents:call-to-action:text')}</p>
                </div>
                <div className="call-action-v1-in inner-btn page-scroll">
                  <a
                    href={t('Home:contents:call-to-action:btn-url')}
                    className="btn-u btn-u-md"
                  >
                    {t('Home:contents:call-to-action:btn-text')}
                  </a>
                </div>
              </div>
            </div>
            {/* <div className="owl-carousel-style-v2 margin-bottom-40">
            <div className="headline">
              <h2>{t('Home:contents:our-clients-title')}</h2>
            </div>
            <ClientsSliderHome />
          </div> */}
          </div>
        </div>
      </Layout>
    </>
  );
};

export default withLocaleProvider('it', IndexPage);
