import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'gatsby';

const RevSliderHome = () => {
  const {t} = useTranslation('Home');

  useEffect(() => {
    if (window?.RevolutionSlider.initRSfullWidth !== undefined) {
      window.RevolutionSlider.initRSfullWidth();
    }
  }, []);

  return (
    <div className="tp-banner-container">
      <div className="tp-banner">
        <ul>
          <li
            className="revolution-mch-1"
            data-transition="fade"
            data-slotamount="5"
            data-masterspeed="1000"
            data-title="Slide 1"
          >
            <img
              src={t('Home:home-slider:slide-1:image')}
              alt="darkblurbg"
              data-bgfit="cover"
              data-bgposition="left top"
              data-bgrepeat="no-repeat"
            />

            <div
              className="tp-caption revolution-ch1 sft start"
              data-x="center"
              data-hoffset="0"
              data-y="100"
              data-speed="1500"
              data-start="500"
              data-easing="Back.easeInOut"
              data-endeasing="Power1.easeIn"
              data-endspeed="300"
            >
              {t('Home:home-slider:slide-1:title')}
            </div>

            <div
              className="tp-caption revolution-ch2 sft"
              data-x="center"
              data-hoffset="0"
              data-y="190"
              data-speed="1400"
              data-start="2000"
              data-easing="Power4.easeOut"
              data-endspeed="300"
              data-endeasing="Power1.easeIn"
              data-captionhidden="off"
              style={{zIndex: 6}}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: t('Home:home-slider:slide-1:text'),
                }}
              />
            </div>

            <div
              className="tp-caption sft"
              data-x="center"
              data-hoffset="0"
              data-y="310"
              data-speed="1600"
              data-start="2800"
              data-easing="Power4.easeOut"
              data-endspeed="300"
              data-endeasing="Power1.easeIn"
              data-captionhidden="off"
              style={{zIndex: 6}}
            >
              <Link
                to={t('Home:home-slider:slide-1:btn-1-url')}
                className="btn-u btn-brd btn-brd-hover btn-u-light"
              >
                {t('Home:home-slider:slide-1:btn-1-text')}
              </Link>
            </div>
          </li>

          <li
            className="revolution-mch-1"
            data-transition="fade"
            data-slotamount="5"
            data-masterspeed="1000"
            data-title="Slide 2"
          >
            <img
              src={t('Home:home-slider:slide-2:image')}
              alt="darkblurbg"
              data-bgfit="cover"
              data-bgposition="left top"
              data-bgrepeat="no-repeat"
            />

            <div
              className="tp-caption revolution-ch1 sft start"
              data-x="center"
              data-hoffset="0"
              data-y="100"
              data-speed="1500"
              data-start="500"
              data-easing="Back.easeInOut"
              data-endeasing="Power1.easeIn"
              data-endspeed="300"
            >
              {t('Home:home-slider:slide-2:title')}
            </div>

            <div
              className="tp-caption revolution-ch2 sft"
              data-x="center"
              data-hoffset="0"
              data-y="190"
              data-speed="1400"
              data-start="2000"
              data-easing="Power4.easeOut"
              data-endspeed="300"
              data-endeasing="Power1.easeIn"
              data-captionhidden="off"
              style={{zIndex: 6}}
            >
              {t('Home:home-slider:slide-2:text')}
            </div>
            <div
              className="tp-caption sft"
              data-x="center"
              data-hoffset="0"
              data-y="310"
              data-speed="1600"
              data-start="2800"
              data-easing="Power4.easeOut"
              data-endspeed="300"
              data-endeasing="Power1.easeIn"
              data-captionhidden="off"
              style={{zIndex: 6}}
            >
              <Link
                to={t('Home:home-slider:slide-2:btn-1-url')}
                className="btn-u btn-brd btn-brd-hover btn-u-light"
              >
                {t('Home:home-slider:slide-2:btn-1-text')}
              </Link>
            </div>
          </li>
          <li
            className="revolution-mch-1"
            data-transition="fade"
            data-slotamount="5"
            data-masterspeed="1000"
            data-title="Slide 3"
          >
            <img
              src={t('Home:home-slider:slide-3:image')}
              alt="darkblurbg"
              data-bgfit="cover"
              data-bgposition="left top"
              data-bgrepeat="no-repeat"
            />

            <div
              className="tp-caption revolution-ch1 sft start"
              data-x="center"
              data-hoffset="0"
              data-y="100"
              data-speed="1500"
              data-start="500"
              data-easing="Back.easeInOut"
              data-endeasing="Power1.easeIn"
              data-endspeed="300"
            >
              {t('Home:home-slider:slide-3:title')}
            </div>
            <div
              className="tp-caption revolution-ch2 sft"
              data-x="center"
              data-hoffset="0"
              data-y="190"
              data-speed="1400"
              data-start="2000"
              data-easing="Power4.easeOut"
              data-endspeed="300"
              data-endeasing="Power1.easeIn"
              data-captionhidden="off"
              style={{zIndex: 6}}
            >
              {t('Home:home-slider:slide-3:text')}
            </div>
            <div
              className="tp-caption sft"
              data-x="center"
              data-hoffset="0"
              data-y="310"
              data-speed="1600"
              data-start="2800"
              data-easing="Power4.easeOut"
              data-endspeed="300"
              data-endeasing="Power1.easeIn"
              data-captionhidden="off"
              style={{zIndex: 6}}
            >
              <Link
                to={t('Home:home-slider:slide-3:btn-1-url')}
                className="btn-u btn-brd btn-brd-hover btn-u-light"
              >
                {t('Home:home-slider:slide-3:btn-1-text')}
              </Link>
            </div>
          </li>
        </ul>
        <div className="tp-bannertimer tp-bottom"></div>
      </div>
    </div>
  );
};

export default RevSliderHome;
